.app {
  font-family: 'Poppins';
}
body {
  background-image: url('/public/background.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: left;
  margin: 0;
  height: 100vh;
}

section {
  margin: 20px;
  padding: 20px;
  border: 1px solid #ccc;
}
/* modals */

.modal {
  color: rgb(255, 255, 255);
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  transition: 200ms ease-in-out;
  border: 2px solid darkslategray;
  border-radius: 10px;
  z-index: 10;
  backdrop-filter: blur(45px);
  width: 90%;
  max-width: 90%;
  max-height: 75%;
  overflow: auto;
}

.modal.active {
  transform: translate(-50%, -50%) scale(1);
}

.modal-header {
  padding: 5%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid darkslategray;
}

.modal-header .title {
  font-size: 1em;
  font-weight: bold;
}

.modal-header .close-button {
  cursor: pointer;
  border: none;
  outline: none;
  background: none;
  font-size: 1.25em;
  font-weight: bold;
  color: white;
}

.modal-body {
  padding: 5%;
  font-size: 0.8em;
}
/* scrollbar */
.modal::-webkit-scrollbar {
  width: 5px;
}

.modal::-webkit-scrollbar-thumb {
  background-color: #ffffff48;
  border-radius: 6px;
}

.modal::-webkit-scrollbar-thumb:hover {
  background-color: #ffffff;
}

/* Anpassen des horizontalen Scrollbalkens  */
.modal::-webkit-scrollbar-track {
  background: transparent;
}

.modal::-webkit-scrollbar-track:hover {
  background: transparent;
}
#overlay {
  position: fixed;
  opacity: 0;
  transition: 200ms ease-in-out;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  pointer-events: none;
}

#overlay.active {
  opacity: 1;
  pointer-events: auto;
}

/* projects */

#modal-work {
  display: flex;
  justify-content: center;
}

.projects {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: row;
}

.project1,
.project2,
.project3,
.project4,
.project5,
.project6 {
  border: 2px solid darkslategray;
  width: 90%;
  height: 35vw;
  margin-top: 15px;
  border-radius: 5px;
  position: relative;
}
.project1 {
  background-image: url('../public/aquavista.jpg');
  font-size: 1.5em;
  text-align: center;
  background-size: cover;
}
.project2 {
  /* font-family: 'Parisienne', cursive; */
  font-size: 1.8em;
  background-image: url('../public/thumbnails/blogging.jpg');
  background-size: cover;
  background-position: center;
  overflow: hidden;
}
.project3 {
  font-family: 'Raleway', sans-serif;
  font-size: 1.5em;
  font-weight: 500;
  background-image: url('../public/thumbnails/translator.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #ffffff;
}
.project4 {
  font-size: 2em;
  font-family: 'Handlee', cursive;
  background-image: url('../public/thumbnails/colors.jpg');
  background-size: cover;
  background-position: center;
  overflow: hidden;
}
.project5 {
  font-size: 1.5em;
  /* font-family: 'Handlee', cursive; */
  background-image: url('../public/thumbnails/todolist.jpg');
  background-size: cover;
  background-position: center;
  overflow: hidden;
}
.project6 {
  position: relative;
  overflow: hidden;
  font-family: 'Montserrat', sans-serif;
  font-weight: 100;
  font-size: 1.5em;
}
#background-video {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: auto;
  object-fit: cover;
  transform: translate(-50%, -50%);
  z-index: -1;
}
.c {
  color: green;
}
.o {
  color: red;
}
.l {
  color: yellow;
}
.r {
  color: blue;
}
.s {
  color: orange;
}

.project2 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.project-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  max-width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  background-color: #000000;
}
.project-overlay a {
  text-decoration: none;
}
.project-overlay-text {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  padding: 40px;
}
.project1:hover .project-overlay,
.project5:hover .project-overlay {
  height: 100%;
  opacity: 0.75;
}
.project3:hover .project-overlay {
  height: 100%;
  opacity: 0.9;
}
.project2:hover .project-overlay {
  height: 100%;
  opacity: 1;
  background-color: #ffffff;
}
.project2:hover .project-overlay-text {
  color: rgb(7, 0, 65);
}
.project5:hover .project-overlay {
  height: 100%;
  opacity: 1;
  background-color: rgb(95, 158, 160);
}

.project4:hover .project-overlay {
  height: 100%;
  opacity: 1;
}
.project6:hover .project-overlay {
  height: 100%;
  opacity: 1;
}

/* messagebox */

.messagebox {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.two {
  color: white;
  text-decoration: underline;
}

@media (min-width: 600px) {
  .modal-header .title {
    font-size: 1.1em;
  }
  .modal-body {
    font-size: 0.9em;
  }
}

@media (min-width: 768px) {
  body {
    background-image: url('/public/background.jpg');
    background-size: auto 100%;
  }
}
@media (min-width: 1024px) {
  body {
    background-image: url('/public/background.jpg');
    background-size: cover;
  }
  #modal-projects {
    width: 60%;
  }
  #modal-about {
    width: 60%;
  }
  #modal-contact {
    width: 25%;
  }
  .modal-header {
    padding: 3%;
  }
  .modal-header .title {
    font-size: 1.3em;
  }
  .modal-body {
    font-size: 1em;
  }
  .project-overlay {
    font-size: 1.6em;
  }
  .project1,
  .project2,
  .project3,
  .project4,
  .project5,
  .project6 {
    width: 90%;
    height: 25vw;
  }
}
@media (min-width: 1280px) {
  body {
    background-image: url('/public/background.jpg');
    background-size: cover;
  }
}
